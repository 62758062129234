.ElementList .actions {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.ElementList .p-treetable-tbody tr:hover .actions {
    opacity: 1;
    visibility: visible;
}

.p-treetable-tbody tr.not-active{
    background-color: #eef2ff;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #FFFFFF;
    background: #FFFFFF;
}

.multi-select-active .p-checkbox-icon{
    color:darkgreen !important;
    font-weight: 900;
}

.multi-select-inactive .p-checkbox-icon{
    color: darkred !important;
    font-weight: 900;
}