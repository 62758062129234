@use "../../styles/colors.scss" as color;

.user-image {
  max-width: 2rem;
}

.user-initials {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 2rem;
  width: 2rem;
  max-width: 2rem;

  border-radius: 50%;
  background-color: color.$dark-blue;
  color: color.$light-blue;
}
