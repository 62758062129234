/* src/components/PageLoader.css */
.page-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7); /* Add an overlay background */
  z-index: 9999; /* Ensure it's on top of everything */
}

.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-dots {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 25px;
  height: 25px;
  margin: 0 10px;
  border-radius: 50%;
  background-color: #0056a2;
  animation: dot-move 1.5s infinite ease-in-out;
}

.dot:nth-child(1) {
  animation-delay: 0.2s;
}

.dot:nth-child(2) {
  animation-delay: 0.3s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dot-move {
  0% {
    transform: translateX(-20px);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(-20px);
  }
}
