@use "./styles/colors.scss" as color;
@use "./styles/fonts.scss" as font;

html,
body {
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
  width: 100%;
  //height: 100%;
  //overflow: hidden;
  border-width: 0px;
  /* line 2359, ../src_css/bootstrap/bootstrap.scss */
  .btn-primary {
    color: color.$white;
    background-color: color.$blue;
    border-color: color.$blue;
  }
  /* line 2365, ../src_css/bootstrap/bootstrap.scss */
  .btn-primary:hover {
    color: color.$white;
    background-color: color.$dark-blue;
    border-color: color.$dark-blue;
  }
  /* line 2371, ../src_css/bootstrap/bootstrap.scss */
  .btn-primary:focus,
  .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
    background-color: color.$dark-blue;
  }
  /* line 2375, ../src_css/bootstrap/bootstrap.scss */
  .btn-primary.disabled,
  .btn-primary:disabled {
    color: color.$dark-gray;
    background-color: color.$gray-background;
    border-color: color.$gray-background;
  }
  /* line 2381, ../src_css/bootstrap/bootstrap.scss */
  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: color.$white;
    background-color: color.$blue;
    border-color: color.$blue;
  }
  /* line 2393, ../src_css/bootstrap/bootstrap.scss */
  .btn-secondary {
    color: color.$blue;
    background-color: transparent;
    border-color: color.$blue;
    border: 2px solid;
  }
  /* line 2399, ../src_css/bootstrap/bootstrap.scss */
  .btn-secondary:hover {
    color: color.$white;
    background-color: color.$dark-blue;
    border: 2px solid;
    border-color: color.$dark-blue;
  }
  /* line 2405, ../src_css/bootstrap/bootstrap.scss */
  .btn-secondary:focus,
  .btn-secondary.focus {
    color: color.$dark-blue;
    border-color: color.$dark-blue;
    background-color: transparent;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
    border: 2px solid;
  }
  /* line 2409, ../src_css/bootstrap/bootstrap.scss */
  .btn-secondary.disabled,
  .btn-secondary:disabled {
    color: color.$dark-gray;
    background-color: color.$gray-background;
    border-color: color.$gray-background;
  }
  /* line 2415, ../src_css/bootstrap/bootstrap.scss */
  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: color.$blue;
    background-color: transparent;
    border-color: color.$blue;
  }
  /* line 2422, ../src_css/bootstrap/bootstrap.scss */
  .btn-secondary:not(:disabled):not(.disabled):active:focus,
  .btn-secondary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 50%);
  }
  /* line 61, ../src_css/components/base.scss */
  .button.button-text,
  .btn.button-text {
    background: transparent;
    color: color.$blue;
    padding: 0;
    text-align: center;
  }
  /* line 67, ../src_css/components/base.scss */
  .button.button-text:visited,
  .btn.button-text:visited {
    color: color.$blue;
  }
  /* line 70, ../src_css/components/base.scss */
  .button.button-text:visited:after,
  .btn.button-text:visited:after {
    color: color.$blue;
  }
  /* line 75, ../src_css/components/base.scss */
  .button.button-text:hover,
  .btn.button-text:hover {
    background: transparent;
    color: color.$dark-blue;
    text-decoration: none;
    box-shadow: inset 0 -2px 0 0 color.$dark-blue;
  }
  /* line 79, ../src_css/components/base.scss */
  .button.button-text:hover:after,
  .btn.button-text:hover:after {
    text-decoration: none;
  }

  .button.button-text:focus,
  .btn.button-text:focus {
    background: transparent;
    color: color.$dark-blue;
  }

  .btn:after,
  .dropdown > button:after {
    display: none;
  }

  .btn-secondary-selected,
  .btn-secondary-selected:focus {
    color: color.$white;
    background-color: color.$dark-blue;
    border: 2px solid;
    border-color: color.$dark-blue;
  }

  .p-button:enabled:hover,
  .p-button:not(button):not(a):not(.p-disabled):hover {
    background: color.$dark-blue;
  }

  .extraLarge {
    font-size: font.$extraLarge;
    /*28px*/
  }

  .large2 {
    font-size: font.$large2;
    /*22px*/
  }

  .large {
    font-size: font.$large;
    /*18px*/
  }

  .normal {
    font-size: font.$normal;
    /*16px*/
  }

  .medium {
    font-size: font.$medium;
    /*14px*/
  }

  .small {
    font-size: font.$small;
    /*12px*/
  }

  .gotham-bold {
    font-family: font.$gotham-bold;
  }

  .gotham-narrow-book {
    font-family: font.$gotham-narrow-book;
  }

  .gotham-medium {
    font-family: font.$gotham-medium;
  }

  .medium-gray {
    color: color.$medium-gray;
  }

  .tooltip {
    z-index: 1;
  }

  .modal-tooltip {
    z-index: 1050;
  }

  .tooltip-inner {
    min-width: max-content;
    text-align: left;
  }

  .filter-toggle-btn {
    background-color: transparent;
    font-size: 20px;
  }
}
