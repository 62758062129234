$extraLarge: 1.75rem; // 28pt
$large2: 1.375rem; // 22pt
$large: 1.125rem; // 18pt
$normal: 1rem; // 16pt
$medium: 0.875rem; // 14pt
$small: 0.75rem; // 12pt

$gotham-bold: "GothamBold", Arial, sans-serif;
$gotham-narrow-book: "GothamNarrowBook", Arial, sans-serif;
$gotham-medium: "GothamMedium", Arial, sans-serif;
