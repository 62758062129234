@use "../../styles/colors.scss" as color;
@use "../../styles/fonts.scss" as font;

.headerDiv {
  position: relative;
  max-width: 1200px;
  width: 100%;
  margin: auto;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;

    &__logo {
      width: 48px;
      cursor: pointer;
    }

    &__title {
      color: color.$light-blue;
    }

    &__userInfo {
      padding-right: 1rem;
      text-align: right;

      & > p {
        margin-top: 15px;
      }
    }
  }
  #collasible-nav-dropdown {
    text-align: left;
    left: 0 !important;
    position: relative;
  }
  .logo-conteiner {
    flex-direction: column;
    text-align: center;
  }

  .nav-item :hover {
    background-color: color.$blue;
  }

  .nav-link {
    color: color.$white !important;
    font-weight: bold;
  }

  .active {
    text-decoration: underline !important;
  }
  /*Custom CSS for latest toast design overwriting the primereact Toast CSS*/
  .p-toast {
    position: absolute;
    top: 157px;
    right: 0px;
    width: 100%;
    max-width: 100%;
  }

  .p-toast .p-toast-message {
    border-radius: 0px !important;
  }

  .p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
  .p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close,
  .p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
  .p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
    color: #fff !important;
    font-size: 1rem !important;
  }

  .p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
  .p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close,
  .p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
  .p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
    color: #000 !important;
    font-size: 1rem !important;
  }

  .p-toast .p-toast-message.p-toast-message-error {
    background: #97002e !important;
    border: 0 none !important;
  }

  .p-toast .p-toast-message.p-toast-message-success {
    background: #00708c !important;
    border: 0 none !important;
  }

  .p-toast .p-toast-message .p-toast-message-content {
    padding: 0.5rem !important;
  }

  .p-toast-message.p-toast-message-error.p-toast-message-enter-done .p-toast-message-content,
  .p-toast-message.p-toast-message-success.p-toast-message-enter-done .p-toast-message-content {
    text-align: center;
    color: #fff;
  }

  .p-toast .p-toast-message.p-toast-message-warn,
  .p-toast .p-toast-message.p-toast-message-info {
    background: #faab18 !important;
    border: 0 none !important;
  }

  .p-toast-message.p-toast-message-warn.p-toast-message-enter-done .p-toast-message-content,
  .p-toast-message.p-toast-message-info.p-toast-message-enter-done .p-toast-message-content {
    color: #000;
  }
  /* overwtring line 10938, ../src_css/base/layout.scss from NextPage.css*/
  @media (max-width: 1199.98px) {
    nav#site-specific-nav {
      display: block !important;
    }
  }
  //overwrite NextPage v1
  /* Dropdown Button */
  .dropbtn {
    background-color: transparent;
  }
  // Custom name to avoid conflicting with NextPagev1
  /* The container <div> - needed to position the dropdown content */
  .dropdown2 {
    position: relative;
    max-width: fit-content;
  }
  /* Dropdown Content (Hidden by Default) */
  .row.dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    background-color: color.$white;
    min-width: max-content;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  /* Show the dropdown menu on hover */
  .dropdown2:hover .dropdown-content {
    display: flex;
  }

  .user-profile > div,
  .user-profile > img {
    width: 4rem;
    height: 4rem;
    max-width: 4rem;
  }
  .dropdown-menu.show {
    background: #0066b2;
    width: 100%;
  }

  .nav-item.dropdown {
    box-shadow: 0 0 0 0 #0b2d71 !important;
  }
  .headerDiv.dropdown-menu.show {
    background: #0066b2 !important;
  }
  .headerDiv.active {
    text-decoration: none !important;
    text-align: center;
  }
  .dropdown-menu {
    margin-top: -2px;
  }
}
