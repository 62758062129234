$dark-blue: #0b2d71;
$light-blue: #009dd9;
$light-blue-opacity: #cce0f0;
$blue: #0066b2;
$red: #97012e;
$green: #94af33;

$white: #ffffff;
$light-white: #dcdcdc;
$gray: #969696;
$dark-gray: #6b6d6f;
$medium-gray: #8c8f93;
$light-gray: #ececed;
$black: #000000;
$shadow-black: #00000029;
$gray-background: #dbdcdd;
