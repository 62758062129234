.app {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #f3f3f3;
}

.p-toast {
  z-index: 2147483647 !important;
}

#collasible-nav-dropdown {
  text-align: left;
  left: 25px;
  position: relative;
}
